import React from 'react';
import './Resume.css';

import { Layout } from '../../config/Config';

const Resume = () => {
    return(
        <Layout/>
    )
}

export default Resume;